import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import MainButton from "../components/MainButton";
import MainButtonLink from "../components/MainButtonLink";
import { StaticImage } from "gatsby-plugin-image";
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface HomeProps {
  data: any;
}

export default function Home(props: HomeProps) {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <Layout>
      <section className='section hero is-fullheight'>
        <div className="container hero-bg">
          <div className="hero-body columns is-flex is-flex-direction-row-reverse containerPunchline">
            <div className="column">
              <h1 className="title is-2 punchline">
              <span className="punchline-highlight">{t('punchlineIntro')}</span>
              </h1>
              <p className="subtitle is-4 punchline-text">
                {t('punchlineText')}
              </p>
              <MainButton url='/about' title={t('learnmore')} />
            </div>
            <div className='column heroImageContainer'>
              <StaticImage
                className="heroImg"
                src="../images/students.jpeg"
                placeholder="blurred"
                alt="A photo of Educ participants on the last event"
                height={500}
              />
              <div className="rectangle">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section whyeduc">
        <div className="container">
          <header className="headerTitle">
            <h1 className="title is-2 has-text-centered mainTitle">{t('whyEduc')}</h1>
          </header>
          <div className="columns is-align-items-center">
            <article className="column is-flex is-flex-direction-column is-align-items-center whyArticle">
              <h1 className="title is-3 has-text-centered  whyeductitle">{t('learning')}</h1>
              <div>
                <StaticImage
                  src="../images/openedbook.png"
                  placeholder="blurred"
                  alt={t('learning')}
                  width={189}
                  />
              </div>
              {/* <p className="subtitle is-5 whyeductext has-text-centered">Lorem ipsum dolor sit amet, <span className="whyeductextspan">consectetur adipiscing elit</span>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
            </article>
            <div className="is-flex is-justify-content-center">
              <StaticImage
                src="../images/plus.png"
                alt="plus"
                width={80}
                />
            </div>
            <article className="column is-flex is-flex-direction-column is-align-items-center whyArticle">
              <h1 className="title is-3 has-text-centered  whyeductitle">{t('traveling')}</h1>
              <div>
                <StaticImage
                  src="../images/travelglobe.png"
                  placeholder="blurred"
                  alt={t('traveling')}
                  width={189}
                  />
              </div>
              {/* <p className="subtitle is-5 whyeductext has-text-centered">Lorem ipsum dolor sit amet, <span className="whyeductextspan">consectetur adipiscing elit</span>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
              </article>
            <div className="is-flex is-justify-content-center">
              <StaticImage
                src="../images/plus.png"
                alt="plus"
                width={80}
                />
            </div>
            <article className="column is-flex is-flex-direction-column is-align-items-center whyArticle">
              <h1 className="title is-3 has-text-centered  whyeductitle">{t('networking')}</h1>
              <div>
                <StaticImage
                  src="../images/networking.png"
                  placeholder="blurred"
                  alt={t('networking')}
                  width={189}
                  />
              </div>
              {/* <p className="subtitle is-5 whyeductext has-text-centered">Lorem ipsum dolor sit amet, <span className="whyeductextspan">consectetur adipiscing elit</span>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
            </article>
          </div>
        </div>
      </section>
      <section className="section hero is-medium">
        <div className="container">
          <header className="headerTitle">
            <h1 className="title is-2 has-text-centered mainTitle">{t('statistics')}</h1>
          </header>
          <div className="columns">
            <article className="column is-flex is-flex-direction-row-reverse is-align-items-center">
              <div className="itemStats is-flex is-flex-direction-column-reverse">
                <h1 className="titleStats">{t('projects')}</h1>
                <span className="numberStats">12</span>
              </div>
              <div className="statsImgWrapper">
                <StaticImage
                   src="../images/trophy.png"
                   placeholder="blurred"
                   alt={t('projects')}
                 />
              </div>
            </article>
            <article className="column is-flex is-flex-direction-row-reverse is-align-items-center">
              <div className="itemStats is-flex is-flex-direction-column-reverse">
                <h1 className="titleStats">{t('countries')}</h1>
                <span className="numberStats">06</span>
              </div>
              <div className="statsImgWrapper">
                <StaticImage
                   src="../images/globecountries.png"
                   placeholder="blurred"
                   alt={t('countries')}
                 />
              </div>
            </article>
            <article className="column is-flex is-flex-direction-row-reverse is-align-items-center">
              <div className="itemStats is-flex is-flex-direction-column-reverse is-flex-justify-content-center">
                <h1 className="titleStats">{t('students')}</h1>
                <span className="numberStats">42</span>
              </div>
              <div className="statsImgWrapper">
              <StaticImage
                 src="../images/studentbag.png"
                 placeholder="blurred"
                 alt={t('students')}
               />
               </div>
            </article>
          </div>
        </div>
      </section>
      <section className="section  questionaire">
        <div className="hero is-halfheight">
        <div className="container">
          <header className="headerTitle">
            <h1 className="title is-2 has-text-centered mainTitle">{t('questionaire')}</h1>
          </header>
          <main>
            <p className="is-size-5 questionaireText">
              If you have more questions, feel free to drop us a message.
            </p>
          </main>
          <div className="mainButtonWrapper">
            <MainButtonLink url="https://forms.gle/5GhYvmuZG9bELLyY6" title={t('yourOpinion')} />
          </div>
        </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query($language: String!) {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
          }
          excerpt
        }
      }
    },
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
