import React from 'react';
import { Link } from "gatsby";

interface MenuItemProps {
  url: string;
  title: string;
  className?: string;
}

export default function MainButtonLink(props: MenuItemProps) {
  const { url, title, className = '' } = props;
  return <a href={url} className={`button is-rounded mainbutton ${className}`}>{title}</a>;
}
